<template src="./Recommendations.html"> </template>

<script>
import NomineeResume from "@/components/NomineeResume/NomineeResume";
import jobsService from "@/services/Jobs";

export default {
  name: "Recommendations",
  components: {
    NomineeResume
  },
  data() {
    return {
      current: 1,
      perPage: 5,
      nominees: []
    };
  },
  created() {
    this.getOfferRecommended();
  },
  methods: {
    async getOfferRecommended() {
      try {
        this.nominees = await jobsService.getOfferRecommended(
          this.$route.params.id
        );
      } catch (e) {
        console.error("error", e);
      }
    }
  }
};
</script>

<style scoped lang="scss" src="./Recommendations.scss"></style>
