<template src="./NomineeResume.html">

</template>

<script>
import jobsService from "@/services/Jobs";

export default {
  name: "NomineeResume",
  props:{
    nomineeData:{
      type: Object,
    },
    jobOfferId: {
      type: String
    }
  },
  methods:{
    refuse() {
      this.$buefy.dialog.prompt({
        title: 'Feedback',
        message: 'Has rechazado el proceso de un postulante antes de finalizar. Escribe tu retroalimentación al respecto.',
        inputAttrs: {
          type: 'textarea',
          placeholder: 'Escribir retroalimentación',
          value: ''
        },
        confirmText: 'Enviar feedback',
        cancelText: 'Cancelar',
        trapFocus: true,
        closeOnConfirm: false,
        onConfirm: (value, {close}) => {
          this.$buefy.toast.open(`Your message is sending...`)
          setTimeout(() => {
            this.$buefy.toast.open(`Success message send!`)
            close()
          }, 2000)
        }
      })
    },
    redirectProfileUser(path, follow) {
      let routeData = this.$router.resolve({
        name: path,
        params: { id: follow }
      });
      window.open(routeData.href, "_blank");
    },
    async sendInvitation(userId){
      try {
        const {result} = await jobsService.sendInvitation(this.jobOfferId, userId);
        this.$buefy.toast.open({
          duration: 5000,
          message: result,
          position: 'is-bottom',
          type: 'is-success'
        })
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response.data.message,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss" src="./NomineeResume.scss">

</style>
